import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
// import '../globals.css'
import { CartContext } from '../CartContext';
import ColorSize from '../ColorSize/ColorSize';
import { getFBC, getFBP } from '../FBC/FBC';

function Order(props) {
    const { addToCart, setIsHomPage, setOrderProduct, quantity, inputValue, setInputValue, setQuantity } = useContext(CartContext)
    const { product } = props
    const route = useNavigate()
    const handleOrderRoute = () => {
        setOrderProduct(product)
        setIsHomPage(false)
        route('/checkout')

    }
    useEffect(() => {
        if (product.color) {
            const defaultColor = product.color.split(',')[0];
            setInputValue((prev) => ({ ...prev, color: defaultColor }));
        }

        if (product.size) {
            const defaultSize = product.size.split(',')[0];
            setInputValue((prev) => ({ ...prev, size: defaultSize }));
        }
    }, [product, setInputValue]);

    const handleaddToCart = () => {
        addToCart(product.id, quantity, inputValue.color, inputValue.size)
        if (window.dataLayer) {
            window.dataLayer.push({
                event: "add_to_cart",
                ecommerce: {
                    currency: "BDT",
                    value: product.price,

                    items: [
                        {
                            item_id: product.slug,
                            item_name: product.name,
                            quantity,
                            price: product.price,
                            color: inputValue.color,
                            size: inputValue.size,
                        }
                    ]
                }
            });
        }
        const pixelData = {
            content_ids: product.id,
            content_name: product.name,
            content_price: product.price,
            content_category: product.category,
            conttent: product,
            content_type: 'Product',
            currency: 'BDT',
            value: product.price,
            client_user_agemt: window.navigator.userAgent,
            fbc: getFBC(),
            fbp: getFBP(),

        }


        ReactPixel.track('AddToCart', pixelData);
    }
    return (
        <div>
            <ColorSize product={product} inputValue={inputValue} setInputValue={setInputValue} quantity={quantity} setQuantity={setQuantity} />

            <div className='row mt-3'>
                <div className='col-md-5'>
                    <button id='orderButton' type='button' style={{ background: "rgb(252 0 255)", color: "white" }} onClick={handleOrderRoute} className='btn  w-100 mt-3 '>অর্ডার করুন</button>
                </div>
                <div className='col-md-5'>
                    <button id='addToCartBTn' type='button' onClick={() => handleaddToCart()} className='btn btn-warning w-100 mt-3'>Add to cart</button>
                </div>
            </div>
        </div >
    )
}
export default Order


