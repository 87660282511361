import React, { useEffect, useContext } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { CartContext } from '../../CartContext';
import { getFBC, getFBP } from '../../FBC/FBC';

function OrderConfrim() {
    const { orderData } = useContext(CartContext)
    const route = useNavigate()
    useEffect(() => {

        if (orderData && window.dataLayer) {
            window.dataLayer.push({
                event: "purchase",
                ecommerce: {
                    currency: "BDT",
                    transaction_id: orderData.order_id,
                    value: orderData.price,
                    shipping: orderData.delivery_fee,
                    phone: orderData.phone,
                    cutomerName: orderData.name,
                    cutomerAddress: orderData.address,
                    country: 'Bangladesh',
                    isOldCustomer: orderData.old_customer,

                    items: [
                        {
                            item_id: orderData.product_slug,
                            item_name: orderData.products,
                            quantity: orderData.quantity,
                            price: orderData.price,
                        }
                    ]
                }
            });
        }
       
    }, [orderData, route])
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 400);
    }, [])



    useEffect(() => {
        if (orderData) {
            const pixelData = {
                num_items: orderData.quantity,
                shipping: orderData.delivery_fee,
                phone: orderData.phone,
                customer_name: orderData.name,
                customer_address: orderData.customer_address,
                country: 'Bangladesh',
                is_old_customer: orderData.old_customer,
                transaction_id: orderData.order_id,
                content_ids:orderData.product_slug && orderData.product_slug.split('-').pop(),
                content_name: orderData.products,
                content_price: orderData.price,
                content_variant: [orderData.size, orderData.color].join(', '),
                conttent: orderData,
                content_type: 'Product',
                currency: 'BDT',
                value: orderData.price * orderData.quantity,
                client_user_agemt: window.navigator.userAgent,
                fbc: getFBC(),
                fbp: getFBP(),

            }
            ReactPixel.track('Purchase', pixelData);
        }
        else {
            route('/')
    }
    }, [orderData, route]);


    return <div>
        <Helmet>
            <title>Order Place Successful!! Safa Buy</title>
        </Helmet>
        <div className='text-center  mt-5 pt-5'>
            <div className='mt-5 '>
                <h1 className='text-success '> Order Place Successful !</h1>
                <p className='text-success '>আপনার অর্ডারটি সফলভাবে সম্পন্ন হয়েছে, <br />
                    আমাদের কল সেন্টার থেকে একজন কল দিয়ে <br />
                    আপনার অর্ডারটি কনফার্ম করবে।</p>
                <Link className='' to='/shop'> <button type='button' className=' btn btn-success'>আরো প্রোডাক্ট বাছাই করুন</button></Link>

            </div>
        </div>
    </div>
}

export default OrderConfrim;