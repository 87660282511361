import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPixel from 'react-facebook-pixel';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import { CartContext } from '../CartContext';
import styles from './ProductView.module.css'



function AddToCart(props) {
    const { product } = props
    const { addToCart } = useContext(CartContext)
    const handleaddToCart = () => {
        addToCart(product.id)
        if (window.dataLayer) {
            window.dataLayer.push({
                event: "add_to_cart",
                ecommerce: {
                    currency: "BDT",
                    value: product.price,

                    items: [
                        {
                            item_id: product.slug,
                            item_name: product.name,
                            quantity: 1,
                            price: product.price,
                        }
                    ]
                }
            });
        }
        const pixelData = {
            content_ids: product.id,
            content_name: product.name,
            content_price: product.price,
            content_category: product.category,
            conttent: product,
            content_type: 'Product',
            currency: 'BDT',
            value: product.price,
            client_user_agemt: window.navigator.userAgent,
        }

        
        ReactPixel.track('AddToCart', pixelData);
    }
    return (
        <div>
            <div className={styles.veiwCount}>
                {/* <div>
                    <button onClick={() => handleQuantuty("minus")} className={`${styles.count} ${styles.minusCount}`}>-</button> <span className={`${styles.count} ${styles.quantityCount}`}>{quantity}</span> <button className={`${styles.count} ${styles.plusCount}`} onClick={() => handleQuantuty("plus")}>+</button>
                </div> */}
                <button onClick={() => handleaddToCart(product)} className={`${styles.cartBtn}`} type='button' aria-label="Add this product to your cart">
                    <FontAwesomeIcon icon={faCartPlus} />

                </button>
                {/* <QucikView product={product} /> */}
            </div>
        </div>
    );
}

export default AddToCart;