/* eslint-disable no-plusplus */
export function getFBP() {
    const decodedCookies = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookies.split(';');

    for (let i = 0; i < cookieArray.length; i++) {
        const cookie = cookieArray[i].trim();
        if (cookie.startsWith('_fbp=')) {
            return cookie.substring(5); // Remove '_fbp='
        }
    }

    return ''; // Return an empty string if fbp cookie is not found
}

export function getFBC() {
    const decodedCookies = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookies.split(';');

    for (let i = 0; i < cookieArray.length; i++) {
        const cookie = cookieArray[i].trim();
        if (cookie.startsWith('_fbc=')) {
            return cookie.substring(5); // Remove '_fbc='
        }
    }

    return ''; // Return an empty string if fbc cookie is not found
}
// window.dataLayer = window.dataLayer || [];
// dataLayer.push({
//     'event': 'fbCookies',
//     'fbp': getFBP(),
//     'fbc': getFBC()
// });

