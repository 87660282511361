/* eslint-disable import/no-unresolved */

/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState, useEffect } from 'react';
import ReactImageZoom from 'react-image-zoom';
import Lightbox from "yet-another-react-lightbox";
import  Thumbnails  from 'yet-another-react-lightbox/plugins/thumbnails';
import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from "swiper/modules";
import styles from './Images.module.css'
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "swiper/css";
import "swiper/css/navigation";
import '../../globals.css'


function Images(props) {
    const { imagesx } = props
    const [imageIndex, setImageIndex] = useState(0)
    const handleImage = (index) => {
        setImageIndex(index)

    }
    const [open, setOpen] = useState(false);
    const [slideImage, setSlideImage] = useState([])
    useEffect(() => {
        const imgArr = imagesx && imagesx.map(obj => ({ src: obj.image }));
        setSlideImage(imgArr)

    }, [imagesx])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <div className=''>
                <div className= {styles.bigImage} onClick={() => setOpen(true)}>
                    {/* <img className='bigImage' src={imagesx && imagesx[imageIndex].image} alt="" /> */}
                   {imagesx && <meta property="og:image" content={imagesx[imageIndex].image} />}
                {imagesx && <ReactImageZoom  width={300} zoomWidth={300} zoomPosition="original" img={imagesx[imageIndex].image} />}


                    <Lightbox
                        plugins={[Thumbnails]}
                        open={open}
                        close={() => setOpen(false)}
                        slides={slideImage}
                    />

                </div>
                <div className='swippermain'>

                    <Swiper
                        // onSwiper={setSwiperRef}
                        slidesPerView={3}
                        spaceBetween={0}
                        loop
                        navigation
                        // modules={[Navigation]}
                        className="mySwiper"
                    >
                        {imagesx && imagesx.map((data, index) =>
                         <SwiperSlide className={`${styles.image_list_little} mt-1`} key={data.id}><button className='ms-3' type='button' onClick={() => handleImage(index)}><img width={30} height={30} src={data.image} alt="" /></button> </SwiperSlide>
                        )}
                    </Swiper>

                </div>



            </div>
        </div>
    );
}

export default Images;