import React, { useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { Helmet } from 'react-helmet';
import { fetchUrl } from '../config';
import HeaderSection from '../HeaderSection/HeaderSection';
import SliderTwo from '../SliderTwo/SliderTwo';
import HeaderCategory from '../HeaderCategory/HeaderCategory';
import NewArrivel from '../NewArrivel/NewArrivel';
import HomeCategoryProducts from '../HomeCategoryProducts/HomeCategoryProducts';

function Home() {
    const [phones, setPhones] = useState([])

    useEffect(() => {
        fetch(`${fetchUrl}/api/phone/`)
            .then(res => res.json())
            .then(datas => setPhones(datas))

    }, [])

    ReactPixel.pageView()
    return (
        <div>
            <Helmet>
                <title>Safa Buy</title>
                <meta
                    name='description'
                    content={`
                    Get the best chal (rice) in Safa Buy Online Shop in Bangladesh.
                    অনলাইনে চাল কিনুন,সেরা চাল।
                    contact - ${phones[0] && phones[0].phone}
                `} />
            </Helmet>

            <HeaderSection />
            <SliderTwo />
            <HeaderCategory />
            <NewArrivel />
            <HomeCategoryProducts />
        </div>
    );
}

export default Home;